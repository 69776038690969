/* You can add global styles to this file, and also import other style files */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
	box-sizing: border-box;
}
img {
	max-width: 100%;
}
@font-face {
	font-family: "OpenText Sans";
	font-style: normal;
	font-weight: 400;
	src: url("assets/fonts/OpenTextSans_W_Rg.eot");
	src: url("assets/fonts/OpenTextSans_W_Rg.woff2") format("woff2"),
		url("assets/fonts/OpenTextSans_W_Rg.woff") format("woff");
}
@font-face {
	font-family: "OpenText Sans";
	font-style: normal;
	font-weight: 300;
	src: url("assets/fonts/OpenTextSans_W_Lt.eot");
	src: url("assets/fonts/OpenTextSans_W_Lt.woff2") format("woff2"),
		url("assets/fonts/OpenTextSans_W_Lt.woff") format("woff");
}
@font-face {
	font-family: "OpenText Sans";
	font-style: normal;
	font-weight: 700;
	src: url("assets/fonts/OpenTextSans_W_Bd.eot");
	src: url("assets/fonts/OpenTextSans_W_Bd.woff2") format("woff2"),
		url("assets/fonts/OpenTextSans_W_Bd.woff") format("woff");
}
.fl {
	float: left;
}
.fr {
	float: right;
}
.clear {
	clear: both;
}
.container {
	padding: 0 20px;
}
body {
	font-family: "OpenText Sans" !important;
	background: #eae9e9;
}
a:hover {
	text-decoration: none;
	color: unset;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
table {
	width: 100%;
}
html,
body {
	height: 100%;
}
.mat-drawer {
	position: unset !important;
}
.mat-drawer-backdrop.mat-drawer-shown {
	visibility: hidden !important;
}
.mat-drawer:not(.mat-drawer-side) {
	box-shadow: none;
}
.mat-drawer-container,
.mat-drawer.mat-drawer-push,
.mat-drawer-backdrop.mat-drawer-shown {
	background: none;
}
.mat-menu-content a {
	color: #000;
	border-bottom: 1px solid #a9a9a9;
}
.mat-menu-content {
	width: 200px;
}
.mat-table {
	background: #f9f9f9;
}
.mat-form-field{
	width: 100%;
}
.mat-form-field-infix {
	border-top: 0px solid transparent;
	padding: 2px 0 !important;
}
.mat-form-field-wrapper {
	border: 1px solid #757575;
	padding-bottom: 0px !important;
	background: #fff;
}
.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-form-field-appearance-legacy .mat-form-field-ripple {
	display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}
.manfac tbody,.admin tbody {
	display: block;
	overflow-y: auto;
	height: calc(75vh - 73px);
	width: 100%;
	background: #eae9e9;
	overflow-x: hidden;
	position: absolute;
	left: 0;
}
/* .manfac tbody:hover{overflow-y: scroll;} */

.item-details tbody {
	display: block;
	/*overflow-x: auto;*/
	height: calc(90vh - 180px) !important;
	width: 100%;
}
thead tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}
thead{display: block;}
tbody tr {
	position: relative;
	display: table;
	width: 100%;
	table-layout: fixed;
}
.mat-paginator {
	background: #e8e8e8;
}
.mat-paginator-container {
	min-height: unset;
	justify-content: center !important;
}
.mat-table thead {
	background: #232e72;
	border-bottom: 1px solid #d5d5d5;
}
tr.mat-header-row {
	height: 40px !important;
}
.mat-paginator-range-label,
.mat-paginator-page-size-label,
.mat-paginator-navigation-previous,
.mat-paginator-navigation-next {
	display: none !important;
}

th.mat-header-cell {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 99999;
	border-bottom-width: 0px;
}
.result-w {
	width: 100% !important;
}
.custom-paginator-container {
	justify-content: flex-end;
}
.custom-paginator-counter {
	white-space: nowrap;
	margin: 0px 4px 0px 10px !important;
}
.custom-paginator-page {
	border-radius: 4px;
	outline: none;
	border: none;
	margin: 0.4em;
	font-size: 14px;
	min-width: 24px;
	width: auto;
	min-height: 24px;
	max-height: 24px;
	padding: 1px 6px;
}
.custom-paginator-page-enabled {
	color: #333333;
	background: transparent;
	cursor: pointer;
}
.custom-paginator-page-enabled:hover {
	background: #232e72;
	color: #fff;
}
.custom-paginator-page-enabledhover {
	background-color: #dfe3e6;
}
.custom-paginator-page-disabled {
	background-color: #232e72;
	color: #fff;
}
.custom-paginator-arrow-disabled {
	color: #dfe3e6;
}
.custom-paginator-arrow-enabled {
	color: #999999;
}
.custom-tooltip{
	background: #c4c4c4;
	color: #000 !important;
	font-size: 15px;
	transform-origin: right center;
}
/* .mat-tooltip-panel::before {
	content: "";
	position: absolute;
	top: 20px;
	border-width: 6px;
	left: 2px;
	border-style: solid;
	border-color: transparent #c4c4c4 transparent transparent;
} */
select.mat-input-element {
	height: 23px;
    top: 0px !important;
    font-size: 14px;
    margin-bottom: 0 !important;
}
.mat-form-field-label{
    left:10px !important;
	color:#757575 !important;
}
.user-form .mat-form-field-empty.mat-form-field-label, .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: block;
    left: 10px !important;
    color: #000 !important;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
	right: 10px !important;
}
dp-time-select .dp-time-select-control-down, dp-time-select .dp-time-select-control-up{
	line-height: 0.1 !important
}
dp-day-calendar .dp-next-month, dp-day-calendar .dp-prev-month{
	pointer-events: none !important;
}
option {
	font-size: 14px;
}
.mat-form-field-type-mat-native-select .mat-input-element {
	padding: 4px 30px 4px 10px !important;
}
.mat-row-link {
	text-decoration: underline;
	color: rgba(0, 0, 0, 0.87);
}
.mat-row-link:hover {
	text-decoration: underline;
	color: rgba(0, 0, 0, 0.87);
}
.cal-icon {
	position: absolute;
	right: 12px;
	top: 6px;
}
[hidden] {
	display: none !important;
}
.mat-dialog-container {
	box-shadow: none !important;
	background: none !important;
}
.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.6) !important;
}
.modal-dialog {
	max-width: 850px;
	margin: 4.75rem auto;
}
.mat-elevation-z8 {
	box-shadow: none;
	position: relative;
}

th.mat-header-cell,
.mat-cell {
	vertical-align: middle !important;
}
tr.mat-header-row,
tr.mat-row {
	height: calc(8vh - 30px) !important;
}
tr.mat-header-row {
	height: 40px !important;
}
table th.mat-header-cell {
	font-size: 17px !important;
	color: #fff;
}
.modal-title {
	margin: 0 0 0 !important;
	font-size: 20px !important;
	font-weight: 500 !important;
}
.modal-content {
	background: #f9f9f9;
}
dp-calendar-nav .dp-current-location-btn {
	display: none;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
	border: none !important;
}
.toast-top-center {
	top: 90px !important;
}
.mat-sort-header-arrow {
	color: #fff !important;
}
.mat-menu-item {
	line-height: 40px !important;
	height: 40px !important;
}
.mat-menu-content:not(:empty) {
	padding: 0 !important;
}
/* .mat-drawer-inner-container {
	overflow-y: hidden !important;
} */
.mat-drawer-inner-container {
	overflow-y: auto !important;
    overflow-x: auto !important;
}
.mat-paginator {
	position: fixed !important;
	bottom: 0px;
	width: 100%;
	left: 0;
	text-align: center;
}
.info.mat-snack-bar-container{
    background-color: #2196f3;
    color: #fff;
}
.mat-snack-bar-container{
	padding: 0px 10px !important;
	box-shadow: none !important;
}
.success.mat-snack-bar-container{
    background: #00cc00;
    color: #fff;
}
.error.mat-snack-bar-container{
	background: #D8000C;
	color:#fff;
}
::-webkit-scrollbar {
	width: 12px;
	background: lightgray;
}
body::-webkit-scrollbar-track,
body::-webkit-scrollbar{
	display: none;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(35, 46, 114, 0.12);
	scrollbar-color: #128612 #004e00;
}

::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	background: #eae9e9;
}

.inner-list .mat-form-field-infix {
    width: 165px !important;
	height: 48px;
	display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: left;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #232e72 !important;
}
@media (min-height: 800px) and (max-height: 1080px){
	.manfac tbody{height:calc(82vh - 60px)}
}
@media (min-height:1080px) and (max-height:1440px){
	.manfac tbody{height:calc(82vh - 50px)}
}
@media  (min-height:1440px) and (max-height:2160px){
	.manfac tbody{height:calc(87vh - 50px)}
	tr.mat-row {
		height: calc(8vh - 5px) !important;
	}
}
